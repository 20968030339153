var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login row no-gutters" }, [
    _c("div", { staticClass: "col-md-4" }, [
      _c("div", { staticClass: "login-form row no-gutters" }, [
        _vm._m(0),
        _c("form", { staticClass: "login-form__form shadow--1 col-12" }, [
          _c("div", { staticClass: "login-form__title_bold" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
          _c("div", { staticClass: "login-form__title" }, [
            _vm._v(
              "If you do not see your recovery email, be sure to check your spam filter for an email from info@getgrab.com"
            ),
          ]),
        ]),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-form__logo-container col-12" }, [
      _c("div", { staticClass: "login-form__logo" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-top": "15px" } }, [
      _c("a", { staticClass: "login-form__link", attrs: { href: "/login" } }, [
        _vm._v("Go back to sign in"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }