<template>
  <div class="login row no-gutters">
    <div class="col-md-4">
      <div class="login-form row no-gutters">
        <div class="login-form__logo-container col-12">
          <div class="login-form__logo" />
        </div>

        <form class="login-form__form shadow--1 col-12">
          <div class="login-form__title_bold">{{ headerText }}</div>
          <div class="login-form__title">If you do not see your recovery email, be sure to check your spam filter for an email from info@getgrab.com</div>
        </form>

      </div>
      <div style="margin-top: 15px;">
                          <a
                class="login-form__link"
                href="/login"
            >Go back to sign in</a></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "components/cleverly/FormInput";

export default {
    name: "ResetPassword",
    data() {
        return {
            email: "",
            invitationCode: "",
            password: "",
            confirmPassword: "",
        };
    },
    computed: {
        headerText() {
            return `A recovery email was sent to ${this.$route.params.email}`
        }
    },
    components: {
        FormInput
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

.login {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  margin-top: spacing(xl);
}

.login-form {
  display: flex;
  justify-content: center;

  &__logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: spacing(xxl);
  }

  &__logo {
    width: 228px;
    height: 110px;
    background-image: url("../img/g_hub_white.svg");
    background-size: cover;
  }

  &__form {
    max-width: 500px;
    padding: spacing(xl);
    padding-top: spacing(sm);
    background-color: #fff;
    border-radius: 3px;
  }

  &__title {
    margin-bottom: spacing(sm);
  }
    &__title_bold {
      font-weight: bold;
    margin-bottom: spacing(sm);
  }

  &__input-container {
    margin-bottom: spacing(sm);
  }

  &__button {
    padding: spacing(xxs) spacing(sm);
    margin-bottom: spacing(md);
    background-color: $brand_color;
    color: #fff;
    border: 0;
    border-radius: 3px;
  }

  &__link {
    color: $brand_color;
  }
}
</style>
