// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/g_hub_white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login[data-v-5198bb90] {\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  padding: 0 20px;\n  margin-top: 42px;\n}\n.login-form[data-v-5198bb90] {\n  display: flex;\n  justify-content: center;\n}\n.login-form__logo-container[data-v-5198bb90] {\n  display: flex;\n  justify-content: center;\n  margin-bottom: 60px;\n}\n.login-form__logo[data-v-5198bb90] {\n  width: 228px;\n  height: 110px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n.login-form__form[data-v-5198bb90] {\n  max-width: 500px;\n  padding: 42px;\n  padding-top: 18px;\n  background-color: #fff;\n  border-radius: 3px;\n}\n.login-form__title[data-v-5198bb90] {\n  margin-bottom: 18px;\n}\n.login-form__title_bold[data-v-5198bb90] {\n  font-weight: bold;\n  margin-bottom: 18px;\n}\n.login-form__input-container[data-v-5198bb90] {\n  margin-bottom: 18px;\n}\n.login-form__button[data-v-5198bb90] {\n  padding: 6px 18px;\n  margin-bottom: 24px;\n  background-color: #93BD20;\n  color: #fff;\n  border: 0;\n  border-radius: 3px;\n}\n.login-form__link[data-v-5198bb90] {\n  color: #93BD20;\n}", ""]);
// Exports
module.exports = exports;
